export default {
    'dui-agenda': {
        properties: [{ name: 'id', type: String, watch: undefined }, { name: 'color', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-agenda" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/agenda'); },
    },
    'dui-banner': {
        properties: [{ name: 'background-color', type: String, watch: true }, { name: 'background-image', type: String, watch: true }, { name: 'use-h1', type: Boolean, watch: true }, { name: 'title', type: String, watch: true }, { name: 'description', type: String, watch: true }, { name: 'primary-button', type: String, watch: true }, { name: 'secondary-button', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-banner" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/Banner'); },
    },
    'dui-basefooter': {
        grid: true,
        properties: [],
        loader: function () { return import(/* webpackChunkName: "dui-basefooter" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/basefooter'); },
    },
    'dui-breadcrumb': {
        grid: true,
        properties: [{ name: 'address', type: String, watch: true }, { name: 'actions', type: String, watch: true }, { name: 'more', type: String, watch: true }, { name: 'darkbar', type: Boolean, watch: true }, { name: 'thin-arrow', type: Boolean, watch: true }, { name: 'left-actions', type: Boolean, watch: true }, { name: 'address-splitter', type: String, watch: true }, { name: 'no-bold-address', type: Boolean, watch: true }, { name: 'close-menu-delay', type: Number, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-breadcrumb" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/breadcrumb'); },
    },
    'dui-countdown': {
        grid: true,
        properties: [{ name: 'event', type: String, watch: true }, { name: 'utc-time', type: String, watch: true }, { name: 'logo', type: String, watch: true }, { name: 'firsthalf-description', type: String, watch: true }, { name: 'secondhalf-description', type: String, watch: true }, { name: 'tip', type: String, watch: true }, { name: 'primarycta-text', type: String, watch: true }, { name: 'primarycta-disabled', type: Boolean, watch: true }, { name: 'primarycta-href', type: String, watch: true }, { name: 'primarycta-target', type: String, watch: true }, { name: 'primarycta-color', type: String, watch: true }, { name: 'primarycta-background-color', type: String, watch: true }, { name: 'primarycta-background-hovercolor', type: String, watch: true }, { name: 'secondarycta-text', type: String, watch: true }, { name: 'secondarycta-disabled', type: Boolean, watch: true }, { name: 'secondarycta-href', type: String, watch: true }, { name: 'secondarycta-target', type: String, watch: true }, { name: 'secondarycta-color', type: String, watch: true }, { name: 'day-unit', type: String, watch: true }, { name: 'hour-unit', type: String, watch: true }, { name: 'minute-unit', type: String, watch: true }, { name: 'auto-hide', type: Boolean, watch: true }, { name: 'container-layout', type: Boolean, watch: true }, { name: 'mini', type: Boolean, watch: true }, { name: 'hide-clock', type: Boolean, watch: true }, { name: 'hide-clock-icon', type: Boolean, watch: true }, { name: 'animate-background', type: Boolean, watch: true }, { name: 'border-radius', type: String, watch: true }, { name: 'background-color', type: String, watch: true }, { name: 'background-image', type: String, watch: true }, { name: 'background-mask', type: String, watch: true }, { name: 'logo-margin-right', type: String, watch: true }, { name: 'cta-align', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-countdown" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/countDown'); },
    },
    'dui-course-ui': {
        properties: [{ name: 'community', type: String, watch: false }, { name: 'courseid', type: String, watch: false }, { name: 'status', type: String, watch: false }, { name: 'selecteditemkey', type: String, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-course-ui" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/devlearning/courseui'); },
    },
    'dui-dev-env': {
        properties: [{ name: 'id', type: String, watch: false }, { name: 'labid', type: String, watch: false }, { name: 'defaultfile', type: String, watch: false }, { name: 'community', type: String, watch: false }, { name: 'courseid', type: String, watch: false }, { name: 'showeditor', type: String, watch: false }, { name: 'showtty', type: String, watch: false }, { name: 'envparams', type: String, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-dev-env" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/devlearning/devenv'); },
    },
    'dui-llab-ui': {
        properties: [{ name: 'labid', type: String, watch: false }, { name: 'view', type: String, watch: false }, { name: 'defaultfile', type: String, watch: false }, { name: 'community', type: String, watch: false }, { name: 'showeditor', type: String, watch: false }, { name: 'showtty', type: String, watch: false }, { name: 'step', type: String, watch: false }, { name: 'selecteditemkey', type: String, watch: false }, { name: 'status', type: String, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-llab-ui" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/devlearning/llabui'); },
    },
    'dui-module-ui': {
        properties: [{ name: 'moduleid', type: String, watch: false }, { name: 'community', type: String, watch: false }, { name: 'status', type: String, watch: false }, { name: 'selecteditemkey', type: String, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-module-ui" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/devlearning/moduleui'); },
    },
    'dui-directory': {
        properties: [{ name: 'id', type: String, watch: undefined }, { name: 'url', type: String, watch: undefined }, { name: 'trackname', type: String, watch: undefined }, { name: 'list', type: Object, watch: true }, { name: 'current', type: String, watch: true }, { name: 'playing', watch: true }, { name: 'fixHeight', type: Number, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-directory" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/directory'); },
    },
    'dui-discussion': {
        grid: true,
        properties: [{ name: 'apihost', type: String, watch: false }, { name: 'discussionid', type: String, watch: true }, { name: 'groupid', type: String, watch: true }, { name: 'type', type: String, watch: true }, { name: 'newtopictotop', type: Boolean, watch: false }, { name: 'addonly', type: Boolean, watch: true }, { name: 'readonly', type: Boolean, watch: true }, { name: 'topicurl', type: String, watch: false }, { name: 'labelurl', type: String, watch: false }, { name: 'limit', type: Number, watch: false }, { name: 'loading', type: String, watch: false }, { name: 'labelcursor', type: String, watch: false }, { name: 'bgcolor', type: String, watch: false }, { name: 'topicbgColor', type: String, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-discussion" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/discussion'); },
    },
    'dui-faq': {
        grid: true,
        properties: [{ name: 'background-color', type: String, watch: true }, { name: 'title', type: String, watch: true }, { name: 'list', type: String, watch: true }, { name: 'help', type: String, watch: true }, { name: 'contact-text', type: String, watch: true }, { name: 'contact-link', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-faq" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/faq'); },
    },
    'dui-files': {
        grid: true,
        properties: [{ name: 'store', type: String, watch: false }, { name: 'root', type: String, watch: true }, { name: 'path', type: String, watch: true }, { name: 'readonly', type: Boolean, watch: false }, { name: 'copybtn', type: Boolean, watch: true }, { name: 'width-md', type: Number, watch: false }, { name: 'width-lg', type: Number, watch: false }, { name: 'page-size', type: Number, watch: false }, { name: 'page-num', type: Number, watch: true }, { name: 'page-range', type: Number, watch: true }, { name: 'page-range-md', type: Number, watch: true }, { name: 'page-range-lg', type: Number, watch: true }, { name: 'prev-page-text', type: String, watch: true }, { name: 'next-page-text', type: String, watch: true }, { name: 'first-page-text', type: String, watch: true }, { name: 'last-page-text', type: String, watch: true }, { name: 'hide-new', type: Boolean, watch: true }, { name: 'tasks-floating', type: Boolean, watch: true }, { name: 'tasks-zindex', type: String, watch: true }, { name: 'pathitem-maxlength', type: Number, watch: true }, { name: 'long-pathitem-maxlength', type: Number, watch: true }, { name: 'max-upload-tasks', type: Number, watch: true }, { name: 'hide-folderdesc-tip', type: Boolean, watch: true }, { name: 'folderdesc-position', type: String, watch: true }, { name: 'folderdesc-title', type: String, watch: true }, { name: 'extname-types', type: String, watch: true }, { name: 'login-html', type: String, watch: true }, { name: 'loadingerror-html', type: String, watch: true }, { name: 'hide-rename', type: Boolean, watch: true }, { name: 'allow-edit', type: String, watch: true }, { name: 'edit-image-folder', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-files" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/files'); },
    },
    'dui-github-md-viewer': {
        properties: [{ name: 'text', type: String, watch: true }, { name: 'format', type: String, watch: true }, { name: 'allowiframe', type: Boolean, watch: undefined }, { name: 'sanitized', type: Boolean, watch: undefined }, { name: 'sanitize-add-tag', type: String, watch: undefined }, { name: 'sanitize-add-attr', type: String, watch: undefined }, { name: 'path', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-github-md-viewer" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/GitHubMdViewer'); },
    },
    'dui-highlight-words': {
        properties: [{ name: 'text', type: String, watch: true }, { name: 'keywords', type: String, watch: true }, { name: 'match', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-highlight-words" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/HighlightWords'); },
    },
    'dui-swagger-api': {
        properties: [{ name: 'spec', type: Object, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-swagger-api" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/interactive-api/api-v2'); },
    },
    'dui-swagger-api-v3': {
        properties: [{ name: 'spec', type: Object, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-swagger-api-v3" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/interactive-api/api-v3'); },
    },
    'dui-swagger-client': {
        properties: [{ name: 'spec', type: Object, watch: true }, { name: 'specPath', type: String, watch: true }, { name: 'height', type: Number, watch: undefined }, { name: 'horizontal', type: Boolean, watch: true }, { name: 'hideconfig', type: Boolean, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-swagger-client" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/interactive-api/client-v2'); },
    },
    'dui-swagger-client-v3': {
        properties: [{ name: 'spec', type: Object, watch: true }, { name: 'specPath', type: String, watch: true }, { name: 'height', type: Number, watch: undefined }, { name: 'horizontal', type: Boolean, watch: true }, { name: 'hideconfig', type: Boolean, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-swagger-client-v3" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/interactive-api/client-v3'); },
    },
    'dui-swagger-model': {
        properties: [{ name: 'spec', type: Object, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-swagger-model" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/interactive-api/model-v2'); },
    },
    'dui-swagger-model-v3': {
        properties: [{ name: 'spec', type: Object, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-swagger-model-v3" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/interactive-api/model-v3'); },
    },
    'dui-markdown-editor': {
        grid: true,
        properties: [{ name: 'compress', type: Boolean, watch: false }, { name: 'value', type: String, watch: true }, { name: 'autofocus', type: Boolean, watch: false }, { name: 'disabled', type: Boolean, watch: false }, { name: 'readonly', type: Boolean, watch: false }, { name: 'maxlength', type: Number, watch: false }, { name: 'placeholder', type: String, watch: false }, { name: 'imagefolder', type: String, watch: false }, { name: 'focus', type: Function, watch: false }, { name: 'getValue', type: Function, watch: false }, { name: 'error', type: Boolean, watch: true }, { name: 'allowiframe', type: Boolean, watch: true }, { name: 'replace-regexps', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-markdown-editor" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/markdownEditor'); },
    },
    'dui-markdown-viewer': {
        properties: [{ name: 'text', type: String, watch: true }, { name: 'format', type: String, watch: true }, { name: 'allowiframe', type: Boolean, watch: undefined }, { name: 'sanitized', type: Boolean, watch: undefined }, { name: 'sanitize-add-tag', type: String, watch: undefined }, { name: 'sanitize-add-attr', type: String, watch: undefined }, { name: 'replace-regexps', type: String, watch: undefined }],
        loader: function () { return import(/* webpackChunkName: "dui-markdown-viewer" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/markdownViewer'); },
    },
    'dui-marketplace-case-tile': {
        grid: true,
        properties: [{ name: 'autolayout', type: Boolean, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-marketplace-case-tile" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/marketplace/CaseTile'); },
    },
    'dui-marketplace-solution-tile': {
        grid: true,
        properties: [{ name: 'id', type: String, watch: false }, { name: 'liked', type: Boolean, watch: true }, { name: 'likes', type: Number, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-marketplace-solution-tile" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/marketplace/SolutionTile'); },
    },
    'dui-pagination': {
        grid: true,
        properties: [{ name: 'currentpage', type: Number, watch: true }, { name: 'totalpages', type: Number, watch: true }, { name: 'totalitems', type: Number, watch: true }, { name: 'barsize', type: Number, watch: true }, { name: 'pagesize', type: Number, watch: true }, { name: 'anchorpattern', type: String, watch: true }, { name: 'stopdefaultaction', type: Boolean, watch: true }, { name: 'megajump', type: Boolean, watch: true }, { name: 'increments', type: String, watch: true }, { name: 'theme', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-pagination" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/pagination'); },
    },
    'dui-partnerdashboardheader': {
        properties: [{ name: 'defaultopen', type: String, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-partnerdashboardheader" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/partnerdashboardheader'); },
    },
    'dui-partnerdashboardsearch': {
        grid: true,
        properties: [{ name: 'id', type: String, watch: undefined }, { name: 'color', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-partnerdashboardsearch" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/partnerdashboardsearch'); },
    },
    'dui-popdiscussion': {
        grid: true,
        properties: [{ name: 'apihost', type: String, watch: false }, { name: 'discussionid', type: String, watch: true }, { name: 'type', type: String, watch: true }, { name: 'topicurl', type: String, watch: false }, { name: 'labelurl', type: String, watch: false }, { name: 'limit', type: Number, watch: false }, { name: 'sort', type: String, watch: false }, { name: 'order', type: String, watch: false }, { name: 'theme', type: String, watch: false }, { name: 'loading', type: String, watch: false }, { name: 'labelcursor', type: String, watch: false }, { name: 'topicsradius', type: String, watch: false }, { name: 'title', type: String, watch: false }, { name: 'desciption', type: String, watch: false }, { name: 'actions', type: String, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-popdiscussion" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/popdiscussion'); },
    },
    'dui-registerform': {
        grid: true,
        noshadow: true,
        properties: [{ name: 'munchkinId', type: String, watch: true }, { name: 'formId', type: Number, watch: true }, { name: 'loadingSite', type: String, watch: true }, { name: 'showThanksModal', type: String, watch: true }, { name: 'title', type: String, watch: true }, { name: 'loginReturnUrl', type: String, watch: true }, { name: 'theme', type: String, watch: true }, { name: 'formType', type: String, watch: true }, { name: 'hideLoginProvider', type: String, watch: true }, { name: 'eventName', type: String, watch: true }, { name: 'backgroundImage', type: String, watch: true }, { name: 'btnText', type: String, watch: true }, { name: 'formDescription', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-registerform" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/registerform'); },
    },
    'dui-schema-form': {
        properties: [{ name: 'schema', type: Object, watch: true }, { name: 'uischema', type: Object, watch: true }, { name: 'conditions', type: Array, watch: true }, { name: 'url', type: String, watch: true }, { name: 'request', type: Object, watch: true }, { name: 'buttontext', type: String, watch: true }, { name: 'redirecturl', type: String, watch: true }, { name: 'redirectmessage', type: String, watch: true }, { name: 'message', type: Object, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-schema-form" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/schemaForm'); },
    },
    'dui-slider': {
        properties: [{ name: 'current', type: Number, watch: false }, { name: 'diff', type: Number, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-slider" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/slider'); },
    },
    'dui-study-group-purchase': {
        properties: [{ name: 'status', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-study-group-purchase" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/studyGroup/Purchase'); },
    },
    'dui-study-group-switch': {
        properties: [{ name: 'id', type: String, watch: false }, { name: 'name', type: String, watch: false }, { name: 'on', type: Boolean, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-study-group-switch" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/studyGroup/Switch'); },
    },
    'dui-study-group-topics': {
        grid: true,
        properties: [{ name: 'name', type: String, watch: true }, { name: 'value', type: Array, watch: true }, { name: 'filter', type: String, watch: true }, { name: 'error', type: Boolean, watch: true }, { name: 'show', watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-study-group-topics" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/studyGroup/Topics'); },
    },
    'dui-tab': {
        grid: true,
        properties: [],
        loader: function () { return import(/* webpackChunkName: "dui-tab" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/tab'); },
    },
    'dui-tabs': {
        grid: true,
        properties: [{ name: 'selected', type: Number, watch: true }, { name: 'color', type: String, watch: true }, { name: 'full', type: Boolean, watch: true }, { name: 'tabs', type: String, watch: true }, { name: 'breaks', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-tabs" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/tabs'); },
    },
    'dui-tabs-section': {
        grid: true,
        properties: [{ name: 'title', type: String, watch: true }, { name: 'description', type: String, watch: true }, { name: 'default-active', type: Number, watch: false }, { name: 'background-color', type: String, watch: true }, { name: 'pc-layout', type: String, watch: true }, { name: 'active-indicator-up', type: Boolean, watch: true }, { name: 'nested-prefix', type: String, watch: true }, { name: 'tabs', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-tabs-section" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/tabsSection'); },
    },
    'dui-thinheader': {
        grid: true,
        properties: [{ name: 'subtitlename', type: String, watch: true }, { name: 'subtitlelink', type: String, watch: true }, { name: 'origin', type: String, watch: true }],
        loader: function () { return import(/* webpackChunkName: "dui-thinheader" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/thinheader'); },
    },
    'dui-topic': {
        grid: true,
        properties: [{ name: 'apihost', type: String, watch: false }, { name: 'topicid', type: String, watch: true }, { name: 'labelurl', type: String, watch: false }, { name: 'editlabelsurl', type: String, watch: false }, { name: 'labelcursor', type: String, watch: false }, { name: 'allowdelete', type: Boolean, watch: false }, { name: 'alloweditlabels', type: Boolean, watch: false }, { name: 'labelslocation', type: String, watch: false }, { name: 'embedded', type: Boolean, watch: false }, { name: 'commentsonly', type: Boolean, watch: false }],
        loader: function () { return import(/* webpackChunkName: "dui-topic" */ '/bms/webapps/jenkins/workspace/et-Widget-Library_v4.0.29-stage7/src/widgets/topic'); },
    },
};
